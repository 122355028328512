import * as React from 'react';
import { ReactComponent as FAQDown } from '../assets/icons/nsb-faq-down.svg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

declare const window: any;

interface NSBFaqListProps {
data: object | any;
}

const NSBFaqList: React.FC<NSBFaqListProps> = ({ data }) => {

const showAnswer = (e: any, text: any) => {
    e.target.parentNode.childNodes[2].classList.toggle('hidden');
    e.target.parentNode.childNodes[1].childNodes[1].childNodes[0].classList.toggle('rotate-180');
    window.dataLayer.push({ event: 'faqExpand', faqText: text });
};

return (
<div className="px-6 py-24 lg:p-24 bg-gray-light ">
    <div className="max-w-[1440px] mx-auto">
        <h3 className="text-midnight font-skyBlack text-skyPurple text-[24px] leading-[28px] mb-5 md:tracking-tighter lg:tracking-normal">
            {data.heading}
        </h3>
        {data.faqsList.map((item: any, index: number) => {
            return (
                <div className="relative border-b-2 border-gray-dark" key={index}>
                    <div
                    className="h-16 top-0 w-full left-0 absolute z-20 cursor-pointer block"
                    onClick={e => {
                        showAnswer(e, item.fields.question);
                    }}>
                    </div>
                    <div className="grid grid-cols-12 pb-6 pt-6">
                    <div className="col-span-11 ">
                        <p className="text-midnight text-[20px] leading-[24px] md:text-[24px] md:leading-[28px] font-skyBold">
                        {item.fields.question}
                        </p>
                    </div>
                    <div className="col-span-1 flex items-center justify-end">
                        <FAQDown/>
                    </div>
                    </div>
                    <div className="answer hidden text-[16px] leading-[20px] md:text-[20px] md:leading-[25px]  mb-[20px] mr-4 md:mr-12">
                        {documentToReactComponents(item.fields.answer)}
                    </div>
                </div>
                );
            
            return null; 
            })}
        </div>
    </div>
    );  
};

export default NSBFaqList;
