import * as React from 'react';
import { ReactComponent as ScrollDown } from '../assets/icons/scroll-down.svg';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../config';
import { Aspect, Button } from '@sky-tv-group/components';
import ReactPlayerLoader from '@brightcove/react-player-loader';


interface VideoImageLinkProps {
    data: object | any;
}

const VideoImageLink: React.FC<VideoImageLinkProps> = ({ data }) => {
const [startVideo, setStartVideo] = React.useState(-1);
const myRefs = React.useRef<Array<HTMLDivElement | null>>([]) as any;

const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
const accountId = baseURL[3];

return (
    <div className="bg-lightgrey live-scroll w-full video-intro relative">
        {data.linkType === true ?
        <Aspect ratio={9 / 16}>
            <ReactPlayerLoader accountId={accountId} videoId={data.brightCoveVideoId} options={{ autoplay: false, muted: true, controls: false, loop: true, volume:0, playsinline: true}} />
        </Aspect>
        : <img src={data.thumbnail.fields.file.url} alt={data.thumbnail.fields.file.title}/>
        }
        <div className="absolute top-[300px] z-10 w-full font-skyBold text-white largest-text text-center">{data.heading}</div>
    </div>
    );
};

export default VideoImageLink;
