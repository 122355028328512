import React from "react";
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Button } from '@sky-tv-group/components';

interface ProductHardwareTabbedChooserProps {
    data?: object | any ;
}

const ProductHardwareTabbedChooser: React.FC<ProductHardwareTabbedChooserProps> = ({ data }) => {

    const [productTab, setProductTab] = React.useState(0);

    // console.log(data.skyProductTabs[0])

    const InlineImage = (data: any) => {
        // console.log("DATA: ", data)
        return (
                <img className="float-left mr-2 max-h-[30px]" src={"https:"+data.data.url} alt={data.data.title}/>
            )
        }
    const options = {
        renderNode: {
            [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
                const { file } = node.data.target.fields;
                return <InlineImage data={file} />
            }
        }
    };


    return (
        <div className="w-screen my-12 md:my-16 max-w-[1440px] mx-auto px-4 md:px-8">
            <div className="h3-black-blue prose prose-h2:font-skyBold prose-h2:text-[36px] prose-h2:leading-[44px] md:prose-h2:text-[50px] md:prose-h2:leading-[60px] prose-b:font-skyDemiBold prose-b:text-[#00B1EB] max-w-none -tracking-[1px]">{documentToReactComponents(data.title)}</div>
            <div className="overflow-x-scroll md:overflow-x-auto scrollbar-hide">
                <ul className="text-[24px] leading-[28px] md:text-[28px] md:leading-[32px] p-0 m-0 font-skyBold border-b-[1px] border-black/30 w-[400px] md:w-auto">
                {data.skyProductTabs.map((item: any, index: number)=>{
                    const fieldData = item.fields
                    return (
                        <li className={productTab === index ? "sky-text-daylight inline-block mr-[16px] md:mr-[30px] list-none border-b-[3px] md:border-b-[6px] border-[#00B1EB] pb-4" : "text-black inline-block mr-[16px] md:mr-[30px] list-none pb-4 cursor-pointer hover:opacity-60"} key={index} onClick={()=>setProductTab(index)}>
                            {fieldData.productTitle}
                        </li>
                    )
                })}
                </ul>
            </div>
            <h4 className="pt-[50px] pb-[20px] font-skyBold text-[24px] leading-[28px]">Features:</h4>
            <div className="grid grid-cols-12 gap-6">
                <div className="col-span-12 md:col-span-6">
                    <p className="text-[20px] leading-[24px] pb-[10px]">{data.skyProductTabs[productTab]?.fields.featuresOne}</p>
                    <img className="w-full" src={data.skyProductTabs[productTab]?.fields.featureOneImage.fields.file.url} alt={data.skyProductTabs[productTab]?.fields.featuresOne}/>
                </div>
                <div className="col-span-12 md:col-span-6">
                    <p className="text-[20px] leading-[24px] pb-[10px]">{data.skyProductTabs[productTab]?.fields.featureTwo}</p>
                    <img className="w-full" src={data.skyProductTabs[productTab]?.fields.featureTwoImage.fields.file.url} alt={data.skyProductTabs[productTab]?.fields.featuresOne}/>
                </div>
            </div>
            <h4 className="pt-[20px] pb-[5px] font-skyBold text-[20px] leading-[22px] md:text-[24px] md:leading-[28px]">{data.skyProductTabs[productTab]?.fields.extraOneHeading}</h4>
            <article className="extras-description">
                {documentToReactComponents(data.skyProductTabs[productTab]?.fields.extraOneDescription, options)}
            </article>
            
            <h4 className="pt-[20px] pb-[5px] font-skyBold text-[20px] leading-[22px] md:text-[24px] md:leading-[28px]">{data.skyProductTabs[productTab]?.fields.extraTwoHeading}</h4>
            <article className="extras-description">
                {documentToReactComponents(data.skyProductTabs[productTab]?.fields.extraTwoDescription, options)}
            </article>
            <div className="flex justify-center mt-6 w-full">
                <div className={data.skyProductTabs[productTab]?.fields.secondaryPrice > 0 ? "grid grid-cols-12 mx-auto w-full max-w-[520px]" : "mx-auto"}>
                    {data.skyProductTabs[productTab]?.fields.price > 0 ?
                    <div className="col-span-12 text-center md:text-left md:col-span-6 relative">
                        <span className="font-skyBlack text-[20px] absolute top-[14px] ">$</span>
                        <span className="font-skyBlack text-[56px] pl-4">{data.skyProductTabs[productTab]?.fields.price}</span>
                        <span className="text-[12px] whitespace-nowrap">/{data.skyProductTabs[productTab]?.fields.billingFrequency}</span>
                    </div>
                    :<></>}
                    {data.skyProductTabs[productTab]?.fields.secondaryPrice > 0 ?
                    <div className="col-span-12 text-center md:text-left md:col-span-6 relative">
                        <span className="font-skyBold inline-block mx-4" >OR</span>
                        <span className="font-skyBlack text-[20px] absolute top-[14px] ">$</span>
                        <span className="font-skyBlack text-[56px] pl-4">{data.skyProductTabs[productTab]?.fields.secondaryPrice}</span>
                        <span className="text-[12px] whitespace-nowrap">/{data.skyProductTabs[productTab]?.fields.secondaryBillingFrequency}</span>
                    </div>
                : <></> }
                </div>
            </div>
            <div className={data.skyProductTabs[productTab]?.fields.secondaryPrice > 0 ? "flex-grid grid-cols-12 gap-0 max-w-[350px] mx-auto justify-center text-center pt-4 md:pt-0" : "text-center mx-auto w-full pt-4 md:pt-0"}>
                {data.skyProductTabs[productTab]?.fields.secondaryCtaLink ? 
                <Button className={data.skyProductTabs[productTab]?.fields.secondaryPrice > 0 ? "my-2 col-span-6" : ""} small variant="secondary" colorMode="pure" onClick={()=>window.open(data.skyProductTabs[productTab]?.fields.secondaryCtaLink, "_self")}>{data.skyProductTabs[productTab]?.fields.secondaryCtaText}</Button>
                : null}
                {data.skyProductTabs[productTab]?.fields.primaryCtaLink ? 
                <Button className={data.skyProductTabs[productTab]?.fields.secondaryPrice > 0 ? "my-2 col-span-6" : ""}  small variant="primary" colorMode="pure" onClick={()=>window.open(data.skyProductTabs[productTab]?.fields.primaryCtaLink)}>{data.skyProductTabs[productTab]?.fields.primaryCtaText}</Button>
                : null }
      </div>
        </div>
    )
};

export default ProductHardwareTabbedChooser;
