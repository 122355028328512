import ReactDOM from 'react-dom/client';
import './index.css';
import '@sky-tv-group/style3/main.css'
import 'slick-carousel/slick/slick.css';
import './arrow-roi-overrides.css';
import reportWebVitals from './reportWebVitals';
import { Root } from './Root';
import { sentryService } from './services';

sentryService.init();



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Root/>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
