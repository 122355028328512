    import * as React from 'react';
    import Slider from 'react-slick';

    interface LandingBannerProps {
        data: object | any;
    }

    const LandingBanner: React.FC<LandingBannerProps> = ({ data }) => {

    const settings = {
        dots: true,
        speed: 500,
        fade: true,
        swipeToSlide: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500
    };

    console.log(data.slides[0].fields.image?.fields?.file?.url)
    return (
        <section className="landing-banner w-screen">
            {data.slides?.length > 0 ? 
                <div className="w-full max-w-[1440px] mx-auto h-[460px] md:h-[380px] lg:h-[675px] bg-center bg-cover" >
                    <Slider {...settings}>
                        {data.slides?.map((item: any, index: number)=>{
                            return (
                                <div className="relative w-screen  h-[460px] md:h-[380px] lg:h-[675px] px-4 md:px-8 lg:px-12 max-w-[1440px] mx-auto "  key={index} >
                                    <picture>
                                        <source
                                            media="(min-width: 768px)"
                                            srcSet={item.fields.image?.fields?.file?.url}
                                        />
                                        <img
                                            className="absolute top-0 bottom-0 right-0 h-[460px] md:h-[380px] lg:h-[675px] w-full left-0 object-cover object-center z-4"
                                            src={item.fields.mobileImage?.fields?.file?.url}
                                            alt={item.fields.heading}
                                        />
                                    </picture> 
                                    <div className="max-w-[1280px] w-full pt-[180px] md:pt-[135px] lg:pt-[260px] relative z-10">
                                        <h1 className="text-[53px] leading-[53px] max-w-[240px] md:max-w-[400px] lg:max-w-[600px] lg:text-[100px] lg:leading-[100px] text-white font-skyBlack">{item.fields.heading}</h1>
                                    </div>
                                    {item.fields.buttonLink ? 
                                    <div className="absolute left-0 bottom-16 text-center w-full flex justify-center">
                                        <a href={item.fields.buttonLink} className="text-[12px] lg:text-[16px]  text-white font-skyBold bg-blue-pure rounded-full h-[40px] lg:h-[50px] inline-flex text-center items-center justify-center px-16"><span>{item.fields.buttonText}</span></a>
                                    </div>
                                    :<></>}
                                </div>
                            )
                        })}
                    </Slider>
                </div>
            :<></>}
        </section>
    );
    };

    export default LandingBanner;
