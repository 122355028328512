import  { Helmet } from "react-helmet";

const HeadSection = ( data: any ) => {

    return  (
        <Helmet>
            <link rel="canonical" href={"https://www.sky.co.nz"+data.data.urlSlug}/>
            <meta name="description" content={data.description}/>
            <title>{"Sky: Products - " + data.data.name}</title>
            <meta name="description" content={data.data.description} lang="en-GB" />
            <meta property="og:title" content={data.data.name}/>
            <meta property="og:url" content={"https://www.sky.co.nz/products"+data.data.urlSlug}/>
            <meta property="og:description" content={data.data.description}/>
        </Helmet>
    )
};

export default HeadSection;
