import create from 'zustand';
// import { navService, getBoxes, getProfile, getPackagesSubscription } from '../services/index';
import { navService, getProfile, getBoxes } from '../services/index';
import { NAVIGATION_URL } from '../config';
import { NavigationLinks, T_Product } from '@sky-tv-group/shared';
import { T_Occurrence, T_Profile } from '@sky-tv-group/shared';

export interface Store {
  profile?: T_Profile;
  occurrences?: T_Occurrence[];
  packages?: T_Product[];
  hasError: boolean;
  loading: boolean;
  navLinks: NavigationLinks;
  fetchNavLinks: () => void;
  hasErrorSet: (hasError: boolean) => void;
  setOccurrences: () => void;
  setProfile: () => void;
  setPackages: () => void;
}

const [useStore, store_] = create<Store>((set, get) => ({
  channels: [],
  singleChannelEvents: [],
  navLinks: {
    logoUrl: '/',
    logoSrc: '/',
    TVGuideURL: '/',
    header: [],
    side: [],
    footer: [],
    social: [],
    corporate: [],
  },
  fetchNavLinks: async () => {
    const navLnk = await navService.getLinks(NAVIGATION_URL);
    set({ navLinks: navLnk });
  },
  hasError: false,
  loading: false,
  hasErrorSet: (hasError: boolean) => {
    set({ hasError });
  },
  setOccurrences: async () => {
    let occurrences = await getBoxes();
    set({ occurrences });
  },
  setProfile: async () => {
    let profile = await getProfile();
    set({ profile });
  },
  setPackages: async () => {
    // let packages = await getPackagesSubscription();
    // set({ packages });
  },
}));

export default useStore;
export const store = store_;
