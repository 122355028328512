import App from './App';
import { SPLIT_AUTHORIZATION_KEY, SPLIT_KEY } from './config';
import config from './store/authStore/auth0.config';
import { Auth0Provider } from './store/authStore/auth0';
import { useAuth0 } from './store/authStore/authStore';
import { SplitFactory } from '@splitsoftware/splitio-react';

const onRedirectCallback = () => {
  window.history.replaceState({}, document.title, '/');
};

function AuthenticatedApp() {
  const { loading } = useAuth0();
  return loading ? <App /> : <App />;
}


export function Root() {
  const sdkConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_AUTHORIZATION_KEY,
      key: SPLIT_KEY,
    },
  };

  return (
    <SplitFactory config={sdkConfig} updateOnSdkUpdate={true}>
      <Auth0Provider
          config={{
            domain: config.domain,
            client_id: config.clientId,
            redirect_uri: window.location.origin,
            audience: config.audience,
          }}
          onRedirectCallback={onRedirectCallback}>
        <AuthenticatedApp />
      </Auth0Provider>
    </SplitFactory>
  );
}
