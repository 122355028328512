import React from 'react';
import {
  formatMoneyAutoDecimal,
  NSB_SKY_REWARDS_LOZENGE_TEXT,
  SPLITIO_KEY,
  TANDC_URL,
  useSplitIO,
} from '@sky-tv-group/shared';
import { MarketingRectangle } from './arrow-marketing/MarketingRectangle';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Coupon, ImageDetails } from '@sky-tv-group/graphql';
import { Button } from '@sky-tv-group/components';
import { ReactComponent as Dish } from '../assets/icons/dish.svg';
import { ReactComponent as NoDish } from '../assets/icons/no-dish.svg';
import { ReactComponent as Recording } from '../assets/icons/recording.svg';
import { ReactComponent as NoRecording } from '../assets/icons/no-recording.svg';

export interface GenericPromotionaCardDetails {
  bgColor?: string | null;
  billingFrequency?: string | null;
  campaign?: string | null;
  coupon?: Coupon | null;
  disclaimer?: string | null;
  discountText?: string | null;
  discountedPrice: number | null;
  fromPrice?: string | null;
  imageSrc?: ImageDetails | null;
  lozengeText?: string | null;
  sku?: string | null;
  occurrenceType?: string | null;
  subtitle?: string | null;
  termsAndConditions?: any | null;
  title: string;
  type?: string | null;
  copyrightText?: string | null;
  descriptionList?: string[] | null;
  iconList?: ImageDetails[] | null;
  addLinkText?: string | undefined;
  addLink?: string | URL | undefined;
  detailsText?: string | undefined;
  detailsLink?: string | URL | undefined;
  titleLogo?: any | null
}

export interface GenericPromotionalCardProps {
  card: GenericPromotionaCardDetails;
  buttons?: React.ReactNode;
  bgColor?: string;
  fixedWidth?: number;
  showBottomGradient?: boolean;
  cardType?: 'PRODUCT' | 'OFFER' | 'HARDWARE';
  compactMode?: boolean;
}

interface wrapperProps {
  fixedWidth?: number;
  children: React.ReactNode;
}

const GreenTick = ({ className }: { className?: string }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="0.479492" y="0.339844" width="19.1099" height="19.1099" rx="9.55494" fill="#8DC63F" />
    <path d="M5.57544 9.92974L8.56065 12.8351L14.1994 7.34717" stroke="white" strokeWidth="2.12332" />
  </svg>
);

function wrapDecimalInSpan(num: number): string {
  const [whole, decimal] = num.toFixed(2).split('.');
  return `${whole}<span class="text-lg" style="line-height: 0; margin-top: 19px;">.${decimal}</span>`;
}



export function GenericPromotionalCard({
  card,
  buttons,
  bgColor,
  compactMode,
  fixedWidth,
  cardType = "HARDWARE",
}: GenericPromotionalCardProps) {
  const [showCompetitionFlag] = useSplitIO(SPLITIO_KEY.SKYWEB_SHOW_PROMO_COMPETITION);
  const showCompetition = showCompetitionFlag && card.lozengeText === NSB_SKY_REWARDS_LOZENGE_TEXT;
  
  // console.log("card: ", card)
  return (
    <div
      className="rounded-lg flex flex-col generic-promotional-card w-full relative border-gray-200 border pb-2 "
      style={{
        minHeight: compactMode ? 300 : 600,
        minWidth: fixedWidth ? undefined : 310,
        width: fixedWidth ?? undefined,
        backgroundColor: card.bgColor ?? bgColor,
      }}>
      {card.lozengeText ? (
        <MarketingRectangle
          left={0}
          top={0}
          containerClasses=""
          containerStyle={{ left: 20, top: -16 }}
          mRectTag={card.lozengeText}
          mRectColor={
            (cardType === 'PRODUCT' || cardType === 'HARDWARE')
              ? card.occurrenceType === 'Data'
                ? 'radial-gradient(100% 100% at 0% 0%, #FDB71A 0%, #F26522 100%)'
                : 'radial-gradient(100% 100% at 0% 0%, #F26522 0%, #F96DB1 100%)'
              : '#9100D4'
          }
          mRectTagClasses="text-white text-center sky-h4-bold py-[5px] pl-3 pr-4"
        />
      ) : null}
      <div className="pt-8 pl-5 pr-8 pb-2 flex flex-col gap-4 z-10 ">
        {card.occurrenceType === 'Data' && (
          <div className="flex items-center pt-3 text-black">
            <img className="mr-2 mb-2" src={'https://static.sky.co.nz/sky/icons/icon-color-wifi.svg'} alt="" />
            <span className="sky-h5-black mr-2"> UNLIMITED </span> fibre
          </div>
        )}
        {!card.titleLogo.fields.file.url ? 
        <h5 style={{ fontSize: '40px', lineHeight: '44px' }} className={"font-skyBlack text-black"}>
          {card.title}
        </h5>
        :
        <img src={card.titleLogo.fields.file.url} alt={card.titleLogo.fields.title} className="max-h-[40px] h-40px "/>
        }
        <p className="font-skyBook text-black whitespace-pre-line" style={{ fontSize: '14px', lineHeight: '18px' }}>
          {card.subtitle}
          {showCompetition && (
            <>
              <br />
              <br />
              <b>And, be into WIN a new Sky Box plus a year's Sky subscription on us!</b>
            </>
          )}
        </p>

        {!compactMode && (
        <>
          {cardType !== 'PRODUCT' && (
            <div
              className="relative z-5 w-full"
              style={{
                background: `linear-gradient(to bottom, ${card.bgColor ?? bgColor} , rgba(0,0,0,0) 50%)`,
                height: 150,
                marginBottom: -150,
              }}></div>
          )}

          <div
            className={`w-full z-0 relative rounded-lg overflow-hidden flex-1 ${
              cardType === 'PRODUCT' ? 'flex flex-col justify-center' : ''
            }`}>
            {card?.imageSrc?.url ? (
              <img
                src={card.imageSrc?.url}
                alt={card.imageSrc?.text ?? ''}
                style={{ minHeight: 100 }}
                className={`w-full object-center ${
                  cardType === 'HARDWARE' ? 'object-contain' : 'absolute left-0 top-0 h-full object-cover'
                }`}
              />
            ) : null}
          </div>
        </>
      )}
        
        {card.sku === "POD" ? 
        <>
          <p className="pl-12 ml-0 relative font-skyBold text-[14px]"><NoDish className="absolute left-0 -top-[5px]"/> No Dish Required</p>
          <p className="pl-12 ml-0 relative font-skyBold text-[14px]"><NoRecording className="absolute left-0 -top-[5px]"/> No Recording Capability</p>
        </>
        : null}
        {card.sku === "SKY" || card.sku === "NSB" ? 
        <>
          <p className="pl-12 ml-0 relative font-skyBold text-[14px]"><Dish className="absolute left-0 -top-[5px]"/> Requires Dish <span className="font-skyBook text-[12px]">Free standard installation</span></p>
          <p className="pl-12 ml-0 relative font-skyBold text-[14px]"><Recording className="absolute left-0 -top-[5px]"/> Recording Capability</p>
        </>
        : null}
        <div className="text-black">
          {Number(card.discountedPrice) > 0 && card.fromPrice !== card.discountedPrice?.toString() &&
            (card.fromPrice ? (
              <p className="line-through " style={{ fontSize: '14px', lineHeight: '18px' }}>
                {formatMoneyAutoDecimal(+card.fromPrice)}
              </p>
            ) : (
              <p className=" " style={{ fontSize: '14px', lineHeight: '18px' }}>
                From
              </p>
            ))}
          {Number(card.discountedPrice) > 0 && card.discountedPrice !== null ? (
            <div>
              <div className="ml-4 pr-2 mr-8 relative inline-block">
                <span
                  className="font-skyBlack absolute "
                  style={{ fontSize: 20, lineHeight: '24px', right: '100%', top: 6 }}>
                  $
                </span>
                <span className="font-skyBlack" style={{ fontSize: 46, lineHeight: '56px' }}>
                  <span
                    className="flex flex-row items-start"
                    dangerouslySetInnerHTML={{
                      __html: wrapDecimalInSpan(+formatMoneyAutoDecimal(card.discountedPrice).substring(1)),
                    }}
                  />
                </span>
                {card.billingFrequency && (
                  <span
                    className=" absolute  "
                    style={{ fontSize: 12, lineHeight: '16px', left: 'calc(100% - 33px)', bottom: 6, width: 200 }}>
                    / {card.billingFrequency}
                  </span>
                )}

                
              </div>
            </div>
          ) : null}


          {card.discountText ? (
                  <p className="text-skyPurple font-skyBold text-[16px]">{card.discountText}</p>
          ) : null}

          {card.descriptionList && card.descriptionList.length && (
            <ul className="flex-grow mt-sky-xs pt-3 px-0 mx-0">
              {card.descriptionList &&
                card.descriptionList.map((item: any, index: number) => {
                  return (
                    <li
                      style={{ paddingLeft: 20 }}
                      className="relative text-left tick sky-h7 text-black text-[14.8px] mb-2"
                      key={`${index}-${item}`}>
                      <span className="absolute left-0" style={{ top: 2, width: 14, height: 14 }}>
                        <GreenTick className="mr-sky-sm w-full" />
                      </span>

                      {item}
                    </li>
                  );
                })}
            </ul>
          )}

          {card.iconList && card.iconList.length && !compactMode && (
            <div className="flex-grow flex flex-row flex-wrap align-middle items-center">
              {card.iconList?.map((item: any, index: number) => {
                return (
                  <div className="my-sky-sm mx-sky-xs flex align-content-center justify-center" key={index}>
                    <img
                      className="h-[30px] object-contain"
                      src={item?.url ? item.url : item.fields?.image?.fields?.file?.url}
                      alt={item?.text ? item.text : item.fields?.image?.fields?.file?.text}
                    />
                  </div>
                );
              })}
            </div>
          )}

          {card.termsAndConditions ? (
            <p className="my-3" style={{ fontSize: 10, lineHeight: '12px' }}>
              {documentToReactComponents(card.termsAndConditions)}
            </p>
          ) : null}
          {card.disclaimer ? (
            <p className="" style={{ fontSize: 6, lineHeight: '8px' }}>
              {card.disclaimer}
            </p>
          ) : null}
          {showCompetition && (
            <p className="" style={{ fontSize: 10, lineHeight: '12px' }}>
              Competition <a href={TANDC_URL}>T&Cs</a> apply.
            </p>
          )}
        </div>
      </div>

      {buttons ? <div className={`${compactMode ? 'mt-auto' : 'absolute bottom-0'} w-full`}>{buttons}</div> : null}
      {card.copyrightText && (
        <span style={{ right: 30, bottom: 3, fontSize: 6 }} className="absolute sky-text-xxs text-black">
          {card.copyrightText}
        </span>
      )}
       {Number(card.discountedPrice) === 0 ? 
       <div className="h-[176px]"></div>
       : null }
      <div className="button-cols mx-3 md:mx-1 grid grid-cols-12 gap-0">
        {card.detailsLink ? 
        <Button className="my-2 col-span-6 md:col-span-5" small variant="secondary" colorMode="pure" onClick={()=>window.open(card.detailsLink, "_self")}>{card.detailsText}</Button>
        : null}
        {card.addLink ? 
        <Button className="my-2 col-span-6 md:col-span-7" small variant="primary" colorMode="pure" onClick={()=>window.open(card.addLink)}>{card.addLinkText ? card.addLinkText : "Get "+ card.title}</Button>
        : null }
      </div>

    </div>
  );
}
