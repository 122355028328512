import * as React from 'react';
import VideoPlayer from './video-player';
import debounce from 'lodash.debounce';

interface VideoAccordianProps {
  data: object | any;
}

const VideoAccordian: React.FC<VideoAccordianProps> = ({ data }) => {

  const myRefs = React.useRef<Array<HTMLDivElement | null>>([]) as any;

  const activateFeature = React.useCallback((e: any) => {
    const showDescription = e.target.parentNode.childNodes[3];
    // const hideTitle = e.target.parentNode.childNodes[4];
    e.target.parentNode.classList.toggle('roi-open');
    showDescription.classList.toggle('roi-slide');
    // hideTitle.classList.toggle('roi-slide');
    
    const videoControl = e.target.parentNode.childNodes[1].childNodes[0].childNodes[0].childNodes[0];

    if (e.target.parentNode.classList.contains('roi-open')) {
      videoControl.currentTime = 0;
      videoControl.play();
    } else {
      videoControl.pause();
      videoControl.currentTime = 0;
    }
  }, []);


  const resetFeatures = () => {
    const resetWidths = document.getElementById('video-accordian') as any;
    resetWidths.classList.toggle('roi-default');
  };

  return (
    <div id="video-accordian" className="max-w-6xl mx-auto mb-12" onMouseEnter={resetFeatures} onMouseLeave={resetFeatures}>
      <div id="roi-slide-track" className="-z-1 relative w-full">
        {data?.videos?.map((item: any, index: number) => {
          return (
            <div
              id={`roi-slide-${index}`}
              key={index}
              className="roi-item rounded-md h-64 relative overflow-hidden inline-block pt-10 pb-56 mx-px transition-all duration-100 ">
              <div
                className="absolute z-10 top-0 left-0 w-full h-full bg-transparent cursor-pointer "
                onMouseOver={debounce(activateFeature, 100)}
                onMouseOut={activateFeature}
                onClick={activateFeature}
              />
              <div ref={(el)=> myRefs.current[index] = el }>
                <VideoPlayer videoSrc={item.fields.brightCoveId} />
              </div>
              <div className="absolute top-0 p-3">
                <img src={item.fields.icon?.fields?.file?.url} alt={item.fields.icon?.fields?.file?.title}/>
              </div>
              <div className="roi-slider absolute bottom-0 left-0 right-0 transition-all duration-150 p-3 bg-gradient-to-t from-black bg-opacity-70 to-transparent">
                <h3
                  className="text-white font-skyBlack text-2xl transition-all duration-150 leading-6"
                  dangerouslySetInnerHTML={{ __html: item.fields.headline}}
                />
                <p
                  className="text-white font-skyBook text-sm mt-0 leading-4 caption"
                  dangerouslySetInnerHTML={{ __html: item.fields.description }}
                />
              </div>
              {/* <h3
                  className="text-white font-skyBook text-sm mt-0 leading-4 caption"
                  dangerouslySetInnerHTML={{ __html: item.fields.headline}}
              /> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default VideoAccordian;