    import * as React from 'react';
    import Slider from 'react-slick';
    import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

    interface OfferPanelProps {
        data: object | any;
    }

    const OfferPanel: React.FC<OfferPanelProps> = ({ data }) => {



        return (
            <section className="landing-offer bg-white w-screen pt-16 px-4 md:px-8 lg:px-12">
                <div className="max-w-[1440px] mx-auto border-b-[1px] border-black/50">
                    <div className="grid grid-cols-12 max-w-[1280px] mx-auto text-center lg:text-left">
                        <div className="col-span-12 lg:col-span-4 pt-16 ">
                            <img className="max-w-[240px] md:max-w-[350px] mx-auto" src={data.logo.fields?.file.url} alt="New Sky Box" />
                            <h2 className="text-[36px] md:text-[63px] leading-[36px] md:leading-[73px] font-skyBlack text-midnight my-16">{data.offerText}</h2>
                            <a href={data.buttonLink} className="text-[20px] md:text-[24px] text-white font-skyBold bg-blue-pure rounded-full h-[50px] md:h-[70px] inline-flex text-center items-center justify-center px-16 mb-0"><span>{data.buttonText}</span></a>
                            <div className="mt-3 mb-12 underline lg:max-w-[250px] lg:block text-center lg:text-center">{documentToReactComponents(data.termsText)}</div>
                        </div>
                        <div className="hidden lg:flex  lg:col-span-1"></div>
                        <div className="col-span-12 lg:col-span-7">
                            <img className="w-full" src={data.image.fields?.file.url} alt={data.description} />
                        </div>
                    </div>
                </div>
            </section>
        );
    };

    export default OfferPanel;
