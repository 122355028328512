import * as React from 'react';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../../config';
import ReactPlayerLoader from '@brightcove/react-player-loader';

interface VideoPlayerProps {
  videoSrc: string;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoSrc }) => {

  const videoRef = React.useRef(null) as any;
  const playerRef = React.useRef(null) as any;

  const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
  const accountId = baseURL[3];

  return (
        
        <ReactPlayerLoader 
            ref={videoRef}
            accountId={accountId} 
            videoId={videoSrc} 
            options={{  
                        controls: false, 
                        autoplay: false, 
                        muted: true, 
                        loop: true, 
                        playsinline: true
                    }}
            onSuccess={ () => {
              // console.log("start")
              const player = videoRef.current.player;
              // Show the first frame of the video
              player.play();
              setTimeout(()=> {
                player.pause();
                player.currentTime(0);
              }, 1000);
            }}/>
  );
};

export default VideoPlayer;
