import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface ProductPromoCallUsCtaProps {
    data?: object | any ;
}

const ProductPromoCallUsCta: React.FC<ProductPromoCallUsCtaProps> = ({ data }) => {

    return (
        <div className="px-4 lg:p-8 my-12">
            <div className="rounded-xl lg:rounded-md lg:h-[142px] bg-midnight max-w-[1440px] mx-auto p-8 lg:p-0">
                <div className="p-4 radial-nsb w-full lg:h-[142px] flex justify-start items-center ">
                    <div className="w-full">
                        <article className="text-[24px] leading-[28px] lg:text-[36px] lg:leading-[42px] text-white text-center pb-2 prose prose-b:text-white prose-a:text-white prose-a:font-skySemiBold prose-a:no-underline">{documentToReactComponents(data.heading)}</article>
                        <article className="text-[12px] leading-[16px] lg:text-[14px] lg:leading-[20px] my-0 py-0 text-white text-center">{documentToReactComponents(data.description)}</article>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ProductPromoCallUsCta;
