    import * as React from 'react';
    import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

    interface ContactUsPanelProps {
        data?: any | null;
    }

    const ContactUsPanel: React.FC<ContactUsPanelProps> = ({ data }) => {
        return (
            <section className="">
                <div className="max-w-[1440px] mx-auto border-t-[1px] border-black/20 pt-[100px] pb-[120px] min-h-[480px] flex items-center justify-center w-full">
                    <div className="max-w-[290px] md:max-w-[500px] lg:max-w-[1280px] mx-auto">
                        <article className="text-[30px] md:text-[50px] lg:text-[66px] leading-tight font-skyBlack text-transparent bg-clip-text bg-gradient-to-r from-skyPurple via-[#484ab0] to-midnight inline-block">
                            {documentToReactComponents(data.contactUs)}
                        </article>
                        <p className="mt-8 text-[24px] leading-[28px] font-skyBold">{data.contactUsHours}</p>
                    </div>
                </div>
            </section>
        );
    };

    export default ContactUsPanel;
