import React from "react";
import Slider from 'react-slick';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface ContentRailProps {
    data: object | any;
    size: string | any
}

const ContentRail: React.FC<ContentRailProps> = ({ data, size }) => {

        const settings = {
        dots: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipeToSlide: true,
        variableWidth: true,
        infinite: false,
        arrows: true,
        responsive: [
        {
            breakpoint: 821,
            settings: {
            arrows: false,
            },
        },
        ],
    };

    if(size==="large") {
        return (
                <div className="ml-4 lg:ml-24 relative">
                    <Slider {...settings} className="">
                    {data.contentTiles.map((item: any, index: number) => {
                        return (
                        <div className="" key={index}>
                            <a href={item.fields.link} target="_blank">
                            <img
                            className="mr-4 shadow-lg border rounded-lg "
                            src={item.fields.image.fields.file?.url}
                            alt={item.fields.image.fields.file?.title}
                            />
                            </a>
                        </div>
                        );
                    })}
                    </Slider>
                    <article className="text-midnight font-skyBook text-[16px] leading-[20px] lg:text-[20px] lg:leading-[24px] lg:text-left py-6 px-4 lg:pl-0">
                    {documentToReactComponents(data.heading)}
                    </article>
                    <article className="text-midnight font-skyBook text-[16px] leading-[20px] lg:text-[20px] lg:leading-[24px] lg:text-left py-6 px-4 lg:pl-0">
                    {documentToReactComponents(data.contentRailTcs)}
                    </article>
                </div>
        );
    }
    else {
        return (
            <div className="ml-4 lg:ml-24  relative">
                
                <Slider {...settings} className="">
                {data.contentTiles.map((item: any, index: number) => {
                    // console.log(item)
                    return (
                    <div key={index}>
                        <a href={item.fields.link} target="_blank">
                        <img
                        className="mr-4 rounded-xl w-[186px] h-[105px]"
                        src={item.fields.image.fields.file?.url}
                        alt={item.fields.image.fields.file?.title}
                        />
                        </a>
                    </div>
                    );
                })}
                </Slider>
                <article className="text-midnight font-skyBook text-[16px] leading-[20px] lg:text-[20px] lg:leading-[24px] lg:text-left py-6 px-4 lg:pl-0">
                {documentToReactComponents(data.heading)}
                </article>
                <article className="text-midnight font-skyBook text-[16px] leading-[20px] lg:text-[20px] lg:leading-[24px] lg:text-left py-6 px-4 lg:pl-0">
                    {documentToReactComponents(data.contentRailTcs)}
                </article>
            </div>
    );
    }
};

export default ContentRail