import React from "react";
import { ContentRail } from '.'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface ContentRailsProps {
    data: object | any;
}

const ContentRails: React.FC<ContentRailsProps> = ({ data }) => {

    // console.log(data)
    return (
        <div className="w-screen py-24 bg-gray-light">
            <div className="max-w-[1440px] mx-auto text-center ">
                <h2 className="font-skyBlack font-normal text-skyPurple text-[36px] leading-[40px] lg:text-[56px] lg:leading-[60px] text-center pb-4">{data.heading}</h2>
                <p className="text-[24px] leading-[28px] lg:text-[36px] lg:leading-[40px] my-0 py-0 text-center font-skyBlack font-normal text-skyPurple  px-4 md:px-0">{data.subheading}</p>
                <article className="text-[16px] leading-[20px] lg:text-[20px] lg:leading-[24px] my-0 py-4 text-center pb-10  px-4 md:px-8 lg:px-12">{documentToReactComponents(data.description)}</article>
                <ContentRail data={data.largeRails.fields} size="large"/>
                {data.smallContentRails?.map((item: any, index:number)=>{
                    return (
                        <div key={index}>
                            <ContentRail data={item.fields} size="small"/>
                        </div>
                    )
                })}
                
                {/* <p className="text-midnight font-skyBook text-[16px] leading-[20px] lg:text-[20px] lg:leading-[24px] lg:text-left py-6 px-4 lg:pl-0 lg:ml-24">
                    <span className="font-skyBlack text-skyPurple text-[20px] leading-[24px]  px-4 md:px-0">Always something to watch.</span> <br className="lg:hidden"/>Check out what&apos;s hot.
                </p> */}
                {/* <ContentRail data={data[0]} size="small"/> */}{/* <p className="text-midnight font-skyBook text-[16px] leading-[20px] lg:text-[20px] lg:leading-[24px] lg:text-left py-6 px-4 lg:pl-0 lg:ml-24">
                    <span className="font-skyBlack text-skyPurple text-[20px] leading-[24px]  px-4 md:px-0">All Your Apps in one place.</span> <br className="lg:hidden"/>No need to leave Sky, ready to stream from your new Sky Box homepage.<br/>
                    <span className="text-[12px] leading-[14px] lg:text-[14px] lg:leading-[16px]">Some services require separate subscriptions.</span>
                </p> */}
            </div>
        </div>
    )
};

export default ContentRails