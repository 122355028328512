import React from "react";
import { Button } from '@sky-tv-group/components';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface HeadingProps {
    data: object | any;
}

const Heading: React.FC<HeadingProps> = ({ data }) => {

    return (
        <div className="mx-3 mb-12 py-6 grid grid-cols-12 md:py-13 md:pb-8 md:mb-[60px] max-w-6xl lg:mx-auto ">
            <div className="col-span-12 md:col-span-6">
                <div className="md:text-black text-44px leading-none font-skyBlack mb-4">
                    {documentToReactComponents(data.prouctHeading)}
                </div>
            </div>
            <div className="col-span-12 md:col-span-6 flex md:justify-end">
                <div className="mt-2 pr-0 md:inline md:transform md:scale-75">
                {data.ctaSecondaryLink ?
                <Button small variant="secondary" colorMode="pure" onClick={()=>window.open(data.ctaSecondaryLink, "_self")}>
                    {data.ctaSecondary}
                </Button>
                : null }
                {data.ctaPrimaryLink ?
                <Button small variant="primary" colorMode="pure" onClick={()=>window.open(data.ctaPrimaryLink)}>
                    {data.ctaPrimary}
                </Button>
                : null }
                </div>
            </div>
        </div>
    )
};

export default Heading;
