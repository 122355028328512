    import * as React from 'react';
    import Slider from 'react-slick';
    import { ContactUsPanel, TestimonialPanel } from '../'
    import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
    import useHashScroll from '../useHashScroll';

    interface FeatureSliderProps {
        data: object | any;
    }

    const FeatureSlider: React.FC<FeatureSliderProps> = ({ data }) => {

    const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableHeight: true,
    infinite: false,
    arrows: false,
    responsive: [
        {
            breakpoint: 1200,
                settings: {
                    slidesToShow: 2.4,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows: false,
                    dots: true,
                },
        },
        {
            breakpoint: 600,
                settings: {
                    slidesToShow: 1.8,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows: false,
                    dots: true,
                },
        },
        {
            breakpoint: 480,
                settings: {
                    slidesToShow: 1.2,
                    slidesToScroll: 1,
                    initialSlide: 0,
                    arrows: false,
                    dots: true,
                },
        },
    ],
    };

    // console.log(data) 

    useHashScroll();

    React.useEffect(()=>{
        setTimeout(()=> {
            if(window.location.search.indexOf("compare") > -1) {
                const element = document.getElementById("compare") as any
                element?.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500)
    },[])

    return (
        <section className="landing-features w-screen pb-24 text-center">
            <div className="max-w-[1440px] mx-auto w-full">
                <div className="max-w-[1280px] mx-auto w-full">
                    <h2 className="mt-16 mb-12 text-center text-[60px] leading-tight md:text-[90px] lg:text-[120px] font-skyBlack text-transparent bg-clip-text bg-gradient-to-r from-midnight via-[#484ab0] to-skyPurple inline-block">{data.heading}</h2>
                    {data.slides?.length > 0 ? 
                        <div className="w-full mt-4 lg:mt-16 relative z-[1] max-w-[1280px] mx-auto mb-24">
                        <Slider {...settings}>
                            {data.slides?.map((item: any, index: number)=> {
                                return (
                                    <div key={index}>
                                        <div className="pl-6">
                                            <div className="max-w-[280px] md:max-w-[410px] bg-[#ebebeb] rounded-[25px] py-[40px]">
                                                <h3 className={(item.fields.heading.length < 24 ? "text-[36px] lg:text-[44px]" : "text-[24px] lg:text-[36px]") + " text-midnight font-skyBlack text-center h-[100px] md:h-[140px] px-[40px] flex items-center justify-center leading-[110%]"}>{item.fields.heading}</h3>
                                                <img className="w-full" src={item.fields.image?.fields?.file.url} alt={item.fields.heading} />
                                                <article className="text-left text-midnight text-[16px] md:text-[18px] leading-[18px] md:leading-[20px] h-[140px] flex items-center px-[40px]">{documentToReactComponents(item.fields.description)}</article>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                        </div>
                    :<></>}
                   
                </div>
                <TestimonialPanel data={data}/>
                <div className={data.scrollOnMobile === true ? "overflow-x-scroll md:overflow-auto pb-12" : ""} id="compare">
                    <img src={data.optionalImage?.fields.file.url} alt="Compare Sky Boxes" className="max-w-[800px] lg:max-w-[1280px] mx-auto"/>
                </div>
                <ContactUsPanel data={data}/>
            </div>
        </section>
    );
    };

    export default FeatureSlider;