import React from "react";
import { ReactComponent as TableTick } from '../assets/icons/icon_table_tick.svg';
import { ReactComponent as TableBullet } from '../assets/icons/icon_table_bullet.svg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface TableProps {
    data: object | any;
}


const Table: React.FC<TableProps> = ({ data }) => {   

    const TableHeaderRow = (item: any) => {
        let hcounter=0;
        if (!item.item.fields) return null;
        return (
            <thead className="border-gray-200 border-l border-r border-b">
            <tr>
                {Object.keys(item.item.fields).map((fieldKey) => {
                if (fieldKey.startsWith('column') && item.item.fields[fieldKey]) {
                    hcounter++;
                    return (
                    <th className={hcounter === Number(data?.whichColumnsGrey) ? "text-left font-normal font-skyBold border-gray-200 border-t p-4 align-middle table-fade" : "text-left font-normal font-skyBold border-gray-200 border-t p-4 align-middle"} key={fieldKey}>
                        {documentToReactComponents(item.item.fields[fieldKey])}
                    </th>
                    );
                }
                return null;
                })}
            </tr>
            </thead>
        );
    };

    const TableBodyRow = ({ item, row }: { item: any, row: number })  => {
        // console.log('row: ', item)
        if (!item.fields) return null;
        let counter=0;
        return (
                <tr className={row % 2 === 0 ? 'bg-gray-100 border-gray-200 border-l border-r' : 'bg-white border-gray-200 border-l border-r' }>
                    {Object.keys(item.fields).map((fieldKey) => {
                    if (fieldKey.startsWith('column') && item.fields[fieldKey]) {
                        counter++;
                        const switchText = item.fields[fieldKey].content[0]?.content[0]?.value;
                        let valueObject
                        switch(switchText) {
                            case 'Y':
                                valueObject = <TableTick/>
                            break;
                            case 'N':
                                valueObject = <TableBullet/>
                            break;
                            case 'NA':
                                valueObject = "&nbsp;"
                            break;
                            default:
                                valueObject =   <div className="flex items-center">
                                                    {item.fields[fieldKey].content[0].nodeType === "embedded-asset-block" ? <img className="float-left mr-3 max-h-[30px]" src={"https:"+item.fields[fieldKey].content[0].data.target.fields.file.url} alt={item.fields[fieldKey].content[0].data.target.fields.title} /> : ""}
                                                    {documentToReactComponents(item.fields[fieldKey])}
                                                </div>
                        }

                        
                        return (
                        <td key={fieldKey} className={counter === Number(data?.whichColumnsGrey) ? "px-4 py-3 text-sm table-fade" : "px-4 py-3 text-sm"}>
                            {valueObject}
                        </td>
                        );
                    }
                    return null;
                    })}
                </tr>
        );
    };
    
    return (
        <div className="table-div w-full max-w-[1440px] my-20 mx-4 md:mx-auto overflow-x-scroll border-gray-200 border-b px-4 md:px-8 scrollbar-hide">
            <div className="h3-black-blue prose prose-h2:font-skyBold prose-h2:text-[36px] prose-h2:leading-[44px] md:prose-h2:text-[50px] md:prose-h2:leading-[60px] prose-b:font-skyDemiBold prose-b:text-[#00B1EB] max-w-none -tracking-[1px]">{documentToReactComponents(data.heading)}</div>
            <table className="table-auto w-full max-w-[1280px] md:mx-auto">
                {data.rows?.map((item: any, index: number) =>
                    item.fields.headerRow ? (
                        <TableHeaderRow key={index} item={item} />
                    ) : (
                        <TableBodyRow key={index} item={item} row={index} />
                    )
                )}
            </table>
        </div>
    )
};

export default Table;
