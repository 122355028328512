import * as React from 'react';
import { ReactComponent as FAQDown } from '../assets/icons/icon_faq_down.svg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface InPageTermsProps {
    data: object | any;
}

const InPageTerms: React.FC<InPageTermsProps> = ({ data }) => {
const answers = React.useRef<HTMLDivElement>(null);

const showAnswer = () => {
    answers?.current?.classList.toggle('hidden');
};

if(data?.length > 0) {
    return (
        <div className="pt-24 md:pt-16 pb-20 pl-2 max-w-6xl bg-white md:bg-transparent" id="terms">
            <div className="ml-3 lg:ml-48 pt-0 pb-20 relative mr-8 xl:-mr-14">
                <div className="w-full h-20 absolute max-w-full z-20 cursor-pointer" onClick={() => showAnswer()}></div>
                <div className="grid grid-cols-12 pb-6 pt-6">
                <div className="col-span-11">
                    <div className="text-gray-copy text-xl md:text-md font-skyBold">
                        <h3>Terms and Conditions</h3>
                    </div>
                </div>
                <div className="col-span-1 justify-self-end relative mt-2">
                    <FAQDown />
                </div>
                </div>
                <div className="hidden opacity-70" id="show-answer" ref={answers}>
                {data.map((item: any, index: number) => {
                    return (
                        <div className="mb-6 relative" key={index}>
                            {documentToReactComponents(item.fields.tcsLegalTermsInPageVersion)}
                        </div>
                    );
                })}
                </div>
            </div>
        </div>
    );
}
return null;
};

export default InPageTerms;
