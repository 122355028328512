    import * as React from 'react';
    import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
    import { ReactComponent as VideoPlay } from '../assets/icons/video-play-small.svg';
    import { ReactComponent as ArrowPlay } from '../assets/icons/icon_play.svg';
    import { ReactComponent as ArrowIcon } from '../assets/icons/icon_arrow_play.svg';
    import { ReactComponent as HDIcon } from '../assets/icons/icon-hd.svg';
    import { ReactComponent as Close } from '../assets/icons/arrow-video-close.svg';
    import { ReactComponent as ScrollDown } from '../assets/icons/scroll-down.svg';
    import { HORIZONTAL_VIDEO_PLAYER_URL } from '../config';
    import { Aspect, Button } from '@sky-tv-group/components';
    import ReactPlayerLoader from '@brightcove/react-player-loader';
    import Slider from 'react-slick';

    interface HeroBannerProps {
        data: object | any;
    }

    const HeroBanner: React.FC<HeroBannerProps> = ({ data }) => {
    const [startVideo, setStartVideo] = React.useState(-1);
    const myRefs = React.useRef<Array<HTMLDivElement | null>>([]) as any;

    const scrollToTerms = () => {
    const terms = document.getElementById('terms');
    const showAnswer = document.getElementById('show-answer');
    if (terms && showAnswer) {
        showAnswer.classList.remove('hidden');
        window.scrollTo({
        top: terms?.offsetTop,
        left: 0,
        behavior: 'smooth',
        });
    }
    };


    const settings = {
    dots: false,
    speed: 500,
    slidesToShow: data.videoOrLinkPreviews?.length > 4 ? 4 : data.videoOrLinkPreviews?.length,
    slidesToScroll: 1,
    swipeToSlide: true,
    variableWidth: true,
    infinite: false,
    arrows: false,
    responsive: [
        {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3.2,
            arrows: false,
        },
        },
        {
        breakpoint: 768,
        settings: {
            slidesToShow: 1,
            arrows: false,
        },
        },
    ],
    };

    const showPreview = (e: any, ref: number, show: boolean) => {
    const videoPlayer = myRefs.current[ref].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[0];
    videoPlayer.controls=false;
    videoPlayer.disablePictureInPicture=true;
    videoPlayer.volume=0;
    videoPlayer.muted=true;
    if(show === true) {
        e.target.classList.add("open");
        videoPlayer.play();
        myRefs.current[ref].classList.add("open");
    }
    else {
        e.target.classList.remove("open");
        videoPlayer.pause();
        myRefs.current[ref].classList.remove("open");
    }
    }

    const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
    const accountId = baseURL[3];

  
    return (
    <>
        <div className="hero bg-lightgrey w-full relative">
        
        {data.backgroundVideoId > 0 ? 
        <div className="absolute top-0 min-h-[500px] md:h-auto md:min-h-[400px] w-full overflow-hidden">
            <div className="video-background relative h-[900px] md:h-auto md:min-h-[400px]">
                <ReactPlayerLoader accountId={accountId} videoId={data.backgroundVideoId} options={{ autoplay: true, muted: true, loop: true, playsinline: true}} />
            </div>
        </div>
        : 
        <div className="absolute top-0 min-h-[500px] md:h-auto md:min-h-[400px] w-full overflow-hidden" >
            
        </div>
        }
        <div className="min-h-[500px] md:h-auto md:min-h-[400px] relative pt-14 pl-6 pd:ml-10 lg:pl-12 xl:pl-14 z-10" style={{backgroundImage: `url('`+data.stillImage?.fields.file.url+`')`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
            <div className="absolute top-0 left-0 w-full bottom-0 bg-gradient-to-r from-black to-transparent"></div>
            <div className="grid grid-cols-2 relative z-[1]">
                <div className="col-span-1 flex justify-center ">
                    <div className="w-48 md:w-40">
                    {data.logo?.fields?.file?.url ?
                    <img src={data.logo.fields.file.url} alt={data.logo.fields.file.title} className="max-h-[90px] mx-auto block"/>
                    : null }
                    </div>
                </div>
                <div className="col-span-1 flex justify-end">
                    <div className="mt-2 hidden pr-0 md:inline transform scale-75">
                    {data.ctaSecondaryLink ?
                    <Button small variant="secondary" colorMode="inverse" onClick={()=>window.open(data.ctaSecondaryLink, "_self")}>
                        {data.ctaSecondary}
                    </Button>
                    : null }
                    {data.ctaPrimaryLink ?
                    <Button small variant="primary" colorMode="pure" onClick={()=>window.open(data.ctaPrimaryLink)}>
                        {data.ctaPrimary}
                    </Button>
                    : null }
                    </div>
                </div>
            </div>
            <div className={data.stillImage?.fields?.file?.url ? "pt-[180px] md:w-[45%] md:pt-0 relative z-[1]" : "relative z-[1]"}>
                <div className="font-skyBold text-white text-6xl md:largest-text md:-mb-3 mt-5 md:mt-1 mr-6 ">{data.heading}</div>
                <div className="font-skyBlack text-white text-3xl leading-tight mt-2 md:mr-24 md:mt-[20px] mr-6">{data.description}</div>
            </div>
            <div className="mt-12 pr-6 block md:hidden text-lg relative z-[1]">
                {data.ctaSecondaryLink ?
                <Button small variant="secondary" colorMode="inverse" onClick={()=>window.open(data.ctaSecondaryLink, "_self")}>
                    {data.ctaSecondary}
                </Button>
                : null }
                {data.ctaPrimaryLink ?
                <Button small variant="primary" colorMode="pure" onClick={()=>window.open(data.ctaPrimaryLink)}>
                    {data.ctaPrimary}
                </Button>
                : null }
            </div>
            {data.additionalCopy ? 
            <div className={data.stillImage?.fields?.file?.url ? "text-white font-skyBold text-[16px] mt-[30px] -mb-[100px] md:w-[35%] leading-[140%] relative z-[1]" : "text-white font-skyBold text-[16px] mt-[60px] relative z-[1]"}>{documentToReactComponents(data.additionalCopy)}</div>
            : null }
            {data.offers?.length > 0 ?
            <div className="grid grid-cols-12 mt-24 pt-0 md:pt-16 lg:pt-24 xl:pt-28 md:mt-2 max-w-6xl relative z-[1]" >
            {data.offers?.map((item: any, index: number)=> {
                return (
                    <div className={index === 0 ? "block md:block col-span-12 md:col-span-3 pl-12 md:pl-10 relative pr-20 md:pr-0" : "hidden md:block col-span-12 md:col-span-7 pl-12 md:pl-10 relative pr-20 md:pr-0 lg:-ml-12"} key={index}>
                        <img src={item.fields.icon.fields.file?.url} alt={item.fields.icon.fields.title} className="absolute top-0 left-0"/>
                        <div className={index === 0 ? "text-white font-skyBold text-xl md:text-base leading-5 pt-1 max-w-[120px]" : "text-white font-skyBold text-xl md:text-base leading-5 pt-1"}>
                            {documentToReactComponents(item.fields.offerHeading)}
                        </div>
                        <div className={index===0 ? "text-white text-base md:text-sm max-w-[170px]" : "text-white text-base md:text-sm"}>
                            {documentToReactComponents(item.fields.offerDescription)}
                        </div>
                        <div className={index===0 ? "text-white font-skyBook md:text-xs -ml-13 md:-ml-10 mt-4 md:mt-5 cursor-pointer max-w-[170px]" : "text-white font-skyBook md:text-xs -ml-13 md:-ml-10 mt-4 md:mt-5 cursor-pointer"}>
                            {documentToReactComponents(item.fields.tcs)}
                        </div>
                    </div>
                )
            })}    
            </div>
            : <></>}
            {data.videoOrLinkPreviews?.length > 0 ? 
                <div className="w-full mt-16 md:mt-0 relative z-[1]">
                <Slider {...settings}>
                    {data.videoOrLinkPreviews?.map((item: any, index: number) => {
                    if(item.fields.brightCoveVideoId.indexOf("https://") < 0) {
                        return (
                        <div key={index} className="relative">
                            <div className="relative inline-block pr-4 video-thumb py-12 ">
                            <div className="small-text"><ArrowIcon className="absolute top-0 left-0 mt-13" />
                                <VideoPlay className="absolute video-play object-fill" />
                                <p className="pl-6 md:pl-6 font-skyBold text-white text-xl md:text-sm mb-2 relative -mt-0">
                                {item.fields.heading} <span className="font-skyBook text-white text-xl md:text-sm">{item.fields.runtime}</span>
                                </p>
                            </div>
                            <img
                                src={item.fields.thumbnail.fields.file?.url}
                                alt={item.fields.thumbnail.fields.file?.title}
                            />
                            </div>
                            <div className="bg-midnight video-preview absolute top-0 mr-6 rounded-lg shadow-md" ref={(el)=> myRefs.current[index] = el } style={{zIndex: data.videoOrLinkPreviews.length - index}}>
                            <Aspect ratio={9 / 16}>
                                <ReactPlayerLoader accountId={accountId} videoId={item.fields.brightCoveVideoId} options={{ autoplay: false, muted: true, controls: false, loop: true, volume:0, playsinline: true}} />
                            </Aspect>
                            <div className="grid grid-cols-12 p-2 py-3 absolute bottom-0 w-full">
                                <div className="col-span-8">
                                <div className="small-text">
                                    <p className="font-skyBold text-white text-sm relative leading-none">
                                    {item.fields.heading} <br/><span className="font-skyBook text-white text-xs">{item.fields.runtime} <HDIcon className="inline"/></span>
                                    </p>
                                </div>
                                </div>
                                <div className="col-span-4 pl-6 relative " style={{zIndex: 2+data.videoOrLinkPreviews.length - index}}>
                                    <div className="bg-blue-pure text-white rounded-lg font-skyBold text-[12px] px-2 py-1">
                                        <ArrowPlay className="float-left mr-3 relative mt-1"/> Play
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div className="cursor-pointer absolute inline-block pr-4 video-hover z-20 left-0 bottom-0 w-56 h-56" style={{zIndex: 10+data.videoOrLinkPreviews.length - index}} onMouseOver={(e)=> showPreview(e, index, true)} onMouseOut={(e)=> showPreview(e, index, false)} onClick={() => setStartVideo(index)} >

                            </div>
                        </div>
                        );
                    }
                    else {
                        return(
                        <div key={index} className="relative z-[1]">
                        <div className="relative inline-block pr-4 video-thumb py-12 ">
                            <div className="small-text">
                            <p className="font-skyBold text-white text-xl md:text-sm mb-2 relative -mt-0">
                            {item.fields.heading} <span className="font-skyBook text-white text-xl md:text-sm">{item.fields.runtime}</span>
                            </p>
                            </div>
                            <img
                                src={item.fields.thumbnail.fields.file?.url}
                                alt={item.fields.thumbnail.fields.file?.title}
                            />
                        </div>
                        <div className="cursor-pointer absolute inline-block pr-4 video-hover z-20 left-0 bottom-0 w-56 h-56" style={{zIndex: 10+data.videoOrLinkPreviews.length - index}} onClick={() => window.location.href = item.fields.brightCoveVideoId} >

                        </div>
                        </div>
                        )
                    }

                    })}
                </Slider>
                
                </div>
            :<></>}
            {data.offers?.map((item: any, index: number)=> {
                return (
                    <div className={index === 0 ? "hidden col-span-12 md:col-span-3 pl-12 md:pl-10 relative z-[1] pr-20 md:pr-0" : "block md:hidden mt-[40px] col-span-12 md:col-span-7 pl-12 md:pl-10 relative z-[1] pr-20 md:pr-0 lg:-ml-12"} key={index}>
                        <img src={item.fields.icon.fields.file?.url} alt={item.fields.icon.fields.title} className="absolute top-0 left-0"/>
                        <div className={index === 0 ? "text-white font-skyBold text-xl md:text-base leading-5 pt-1 max-w-[120px]" : "text-white font-skyBold text-xl md:text-base leading-5 pt-1"}>
                            {documentToReactComponents(item.fields.offerHeading)}
                        </div>
                        <div className={index===0 ? "text-white text-base md:text-sm max-w-[170px]" : "text-white text-base md:text-sm"}>
                            {documentToReactComponents(item.fields.offerDescription)}
                        </div>
                        <div className={index===0 ? "text-white font-skyBook md:text-xs -ml-13 md:-ml-10 mt-4 md:mt-5 cursor-pointer max-w-[170px]" : "text-white font-skyBook md:text-xs -ml-13 md:-ml-10 mt-4 md:mt-5 cursor-pointer"}>
                            {documentToReactComponents(item.fields.tcs)}
                        </div>
                    </div>
                )
            })}  
            <div className="absolute -bottom-[16px] w-full mt-[10px] -ml-[60px] z-[1000]">
                <ScrollDown className="pulsate-fwd w-[32px] mx-auto"/>
            </div>
        </div>
        {startVideo >=0 ? (
            <div className="fixed popover w-full h-full top-0 left-0 flex bg-black items-center">
            <div className="absolute top-0 right-0 mr-2 mt-2 cursor-pointer z-50" onClick={() => setStartVideo(-1)}>
                <Close />
            </div>
            <Aspect ratio={9 / 16}>
                <ReactPlayerLoader
                    accountId={accountId}
                    videoId={data.videoOrLinkPreviews[startVideo].fields.brightCoveVideoId}
                    options={{ autoplay: true }}
                />
            </Aspect>
            </div>
        ) : null}
        {/* <div className="block md:hidden absolute bottom-0 left-0 right-0 w-full z-40 black-gradient h-48"></div> */}
        </div>
    </>
    );
    };

    export default HeroBanner;
