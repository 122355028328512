import * as React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GenericPromotionalCard } from './GenericPromotionalCard';
import Slider from 'react-slick';
import { ReactComponent as NextIcon } from '../assets/icons/icon_right_arrow.svg';
import { ReactComponent as PrevIcon } from '../assets/icons/icon_left_arrow.svg';

interface ProductCardCarouselProps {
  data: any;
}

const ProductCardCarousel: React.FC<ProductCardCarouselProps> = ({ data }) => {

  // console.log(data)


  React.useEffect(() => {
    const mobile = document.querySelectorAll('.scroll-on-mobile');
    mobile.forEach((item) => {
      item.scrollLeft = 300;
    });
  }, []);

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: data.cardsToShow.length > 3 ? 3.1 : data.cardsToShow.length,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    variableHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: data.cardsToShow.length > 2 ? 2.2 : data.cardsToShow.length,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.05,
          slidesToScroll: 1,
          initialSlide: 0,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  function NextArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          right: -35,
          top: 260,
        }}
        onClick={onClick}
      >
        <NextIcon />
      </div>
    );
  }

  function PrevArrow(props: any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: 'block',
          zIndex: 50,
          position: 'absolute',
          left: -80,
          top: 260,
        }}
        onClick={onClick}
      >
        <PrevIcon />
      </div>
    );
  }

  return (
    <section className="section-card-panel md:py-[40px] md:pl-[20px] md:pr-[0px] lg:px-[30px] md:mx-auto md:max-w-[1280px]">
      <div className="lg:grid lg:grid-cols-12 mb-[50px] -lg:mb-[10px] lg:mb-[20px] relative">
        <div
          className={
            data.cardPanelHeadingGradient +
            ' lg:w-[600px] mb-[10px] col-span-4 text-[30px] tracking-[-1px]'
          }
        >
          {data.productCategory ? data.productCategory+ " ":""}{documentToReactComponents(data.cardPanelHeading)}
        </div>
      </div>

      <div className={ data.cardsToShow.length < 3 ? "max-w-[800px] mx-auto w-full pl-4" : "max-w-[1280px] w-full mx-auto pl-4" }>
      <Slider {...settings}>

      {/* <div className="md:grid md:grid-cols-2 md:max-w-[12] md:gap-12 md:mx-auto"> */}
      {/* <div className={(data.cardsToShow?.length < 3 ? "max-w-12 " : "")+" md:grid md:grid-cols-12 md:gap-12 mx-auto flex justify-center"}> */}
        {/* <div className="mx-auto max-w-[350px] px-3 py-[30px] col-span-12 md:col-span-6 lg:col-span-4"> */}
          {/* <div className="mx-auto max-w-[350px] py-[30px]">
            <GenericPromotionalCard
                  card={{
                    bgColor: data.cardsToShow[0].fields.bgColor ? data.cardsToShow[0].fields.bgColor : '#000000',
                    billingFrequency: data.cardsToShow[0].fields.priceFrequencyType[0],
                    imageSrc: {
                      text: data.cardsToShow[0].fields.thumbnail?.fields?.file?.text ?? '',
                      url: data.cardsToShow[0].fields.thumbnail?.fields?.file?.url ?? '',
                    },
                    titleLogo: data.cardsToShow[0].fields.titleLogo,
                    lozengeText: data.cardsToShow[0].fields.lozengeText,
                    sku: data.cardsToShow[0].fields.sku,
                    detailsLink: data.cardsToShow[0].fields.detailsLink, 
                    addLink: data.cardsToShow[0].fields.addLink,
                    subtitle: data.cardsToShow[0].fields.description,
                    discountedPrice: data.cardsToShow[0].fields.price as number,
                    discountText: data.cardsToShow[0].fields.discountText,
                    title: data.cardsToShow[0].fields.title,
                    type: data.cardsToShow[0].fields.type,
                    coupon: null,
                    termsAndConditions: data.cardsToShow[0].fields.termsAndConditionsCaption,
                    descriptionList: data.cardsToShow[0].fields.detailsList,
                    copyrightText: data.cardsToShow[0].fields.copyrightText,
                    iconList: data.cardsToShow[0].fields.iconList?.map((icon: any) => ({
                      url: icon?.fields?.file?.url ?? '',
                      text: icon?.fields?.file?.text ?? '',
                    })),
                  }}
                />
          </div> */}
          {/* </div> */}
          {data.cardsToShow.map((card: any, index: number) => {
            const cardData = card.fields;
            return (
              // <div className="mx-auto max-w-[350px] px-3 py-[30px] col-span-12 md:col-span-6 lg:col-span-4"
              //   key={index}
              // >
              <div className="mx-auto max-w-[350px] py-[30px]">
                <GenericPromotionalCard
                  card={{
                    bgColor: cardData.bgColor ? cardData.bgColor : '#000000',
                    billingFrequency: cardData.priceFrequencyType[0],
                    imageSrc: {
                      text: cardData.thumbnail?.fields?.file?.text ?? '',
                      url: cardData.thumbnail?.fields?.file?.url ?? '',
                    },
                    titleLogo: cardData.titleLogo,
                    lozengeText: cardData.lozengeText,
                    sku: cardData.sku,
                    detailsText: cardData.detailsText,
                    detailsLink: cardData.detailsLink, 
                    addLinkText: cardData.addLinkText,
                    addLink: cardData.addLink,
                    subtitle: cardData.description,
                    discountedPrice: cardData.price as number,
                    discountText: cardData.discountText,
                    title: cardData.title,
                    type: cardData.type,
                    coupon: null,
                    termsAndConditions: cardData.termsAndConditionsCaption,
                    descriptionList: cardData.detailsList,
                    copyrightText: cardData.copyrightText,
                    iconList: cardData.iconList?.map((icon: any) => ({
                      url: icon?.fields?.file?.url ?? '',
                      text: icon?.fields?.file?.text ?? '',
                    })),
                  }}
                />
              {/* </div> */}
              </div>
            );
          })}
      {/* </div> */}

      </Slider>
      </div>

      <div className="text-[12px] mb-[20px] lg:mb-[64px] mt-[40px] lg:mt-[50px] mx-auto text-center max-w-[70vw]">
        {documentToReactComponents(data.termsAndConditions)}
      </div>
    </section>
  );
};

export default ProductCardCarousel;
