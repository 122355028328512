import React from "react";
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../config';
import { useIntersection } from 'react-use'

interface ProductHardwareFwVideoCtaProps {
    data?: object | any ;
}

const ProductHardwareFwVideoCta: React.FC<ProductHardwareFwVideoCtaProps> = ({ data }) => {

    const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
    const accountId = baseURL[3];

    const bannerRef = React.useRef() as any
    const videoPlay = React.useRef() as any
    const intersection = useIntersection(bannerRef, {
        root: null,
        rootMargin: '0px',
        threshold: 1
    });

    return (
        <div className="w-screen my-12 md:my-16">
            {data.productLogo?.fields?.file?.url && data.logoAboveText === true ? 
            <img src={data.productLogo.fields.file.url} alt={data.heading} className="mx-auto pb-4"/>
            :<></>}
            {data.heading ?
            <h2 className="font-skyBlack font-normal text-skyPurple text-[36px] leading-[40px] lg:text-[56px] lg:leading-[60px] text-center pb-4 px-4 md:px-8 lg:px-12">{data.heading}</h2>
            : <></>}
            {data.subHeading ?
            <p className="text-[24px] leading-[28px] lg:text-[36px] lg:leading-[40px] my-0 py-0 text-center font-skyBlack font-normal text-skyPurple pb-4 px-4 md:px-8 lg:px-12">{data.subHeading}</p>
            : <></>}
            {data.description ?
            <h4 className="text-[16px] leading-[20px] md:text-[20px] md:leading-[24px] my-0 py-0 pb-4 text-center  prose prose-p:p-0 prose-p:my-0 prose-p:pb-[10px] prose-p:px-4 md:prose-p:px-4 lg:prose-p:px-12 max-w-none">{documentToReactComponents(data.description)}</h4>
            : <></>}
            {data.productLogo?.fields?.file?.url && data.logoAboveText !== true ? 
            <img src={data.productLogo.fields.file.url} alt={data.heading} className="mx-auto py-4"/>
            :<></>}
            {data.brightCoveId ?
            <div className="md:w-[100vw] md:h-[56.25vw] mx-auto py-10 md:p-10 relative ">
                <div className="mb-10 md:mb-0 md:absolute top-0 left-0 md:w-[100vw] md:h-[56.25vw] ">
                    <ReactPlayerLoader accountId={accountId} videoId={data.brightCoveId} options={{ autoplay: data.autoplay, muted: data.autoplay === false ? false : true, loop: data.autoplay === false ? false : true, playsinline: true }} ref={videoPlay}/>
                </div>
            </div>
            : 
            <img className="pt-12 w-auto h-auto mx-auto mb-8 px-4 lg:px-0" src={data.videoPoster?.fields.file.url}/>
            }
            <p className="relative w-full text-[14px] leading-[20px] lg:text-[16px] lg:leading-[18px] my-0 py-0 text-center mt-2">{documentToReactComponents(data.terms)}</p>
            {data.ctaButtonLink ? 
            <div className="w-screen text-center">
                <a href={data.ctaButtonLink} className="hidden md:inline-block h-[48px] align-top font-skyBold px-6 pt-[13px] bg-blue-pure text-white rounded-full my-3 mx-auto">{data.ctaButtonText}</a>
            </div>
            : <></>}
        </div>
    )
};

export default ProductHardwareFwVideoCta;
