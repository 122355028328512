import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

interface JoinNowCTAProps {
    data?: object | any ;
}

const JoinNowCTA: React.FC<JoinNowCTAProps> = ({ data }) => {

    return (
        <div className="nsb-offer pt-24 pb-12 w-screen px-4 lg:px-0">
            <div className="max-w-[970px] mx-auto px-4 md:px-0">
                <h2 className="font-skyBlack font-normal text-skyPurple text-[36px] leading-[40px] lg:text-[56px] lg:leading-[60px] text-center pb-4">{data.heading}</h2>
                <div className="border-black border-gray-dark border-[1px] rounded-md flex justify-start items-center h-auto md:max-h-[162px]">
                    <div className="w-[203px] h-full md:h-[162px] relative">
                        <div className="bg-gradient-to-b from-[#364D9E] to-skyPurple w-[80px] h-[200px] md:w-[130px] md:h-[162px] rounded-l-md">

                        </div>
                        <img src={data.promoBadgeImage?.fields.file.url} className="absolute top-[10px] left-[5px] md:left-[42px] lg:left-[72px] w-[106px] md:w-[132px]"/>
                    </div>
                    <div className="p-4 pl-12">
                        <p className="mt-4 prose prose-a:underline prose-p:text-[14px] prose-p:leading-[18px] md:prose-p:text-[20px] md:prose-p:leading-[24px] prose-p:my-0 prose-p:py-0 prose-p:pb-4 prose-strong:font-skyBold prose-strong:font-normal ">{documentToReactComponents(data.description)}</p>
                    </div>
                </div>
                <article className="prose prose-a:underline prose-a:text-blue-pure prose-a:font-skyBold text-[14px] leading-[18px] md:text-[18px] md:leading-[22px] text-center py-4 w-full max-w-none">
                    {documentToReactComponents(data.termsOrOptions)}
                </article>
            </div>
        </div>
    )
};

export default JoinNowCTA;
