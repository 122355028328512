import { useEffect } from 'react';

function useHashScroll() {
  useEffect(() => {
    const handleHashChange = () => {
        const { hash } = window.location;
        if (hash) {
            const targetElement = document.querySelector(hash);
            
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
                const clickit = targetElement.querySelectorAll(".onclicker")[0] as any
                clickit?.click();
            }
        }
    };

    // Add event listener for hash changes on mount
    window.addEventListener('hashchange', handleHashChange);

    // Scroll to the element based on the initial hash (in case there's a hash in the URL when the component mounts).
    window.scroll(0, 10)
    handleHashChange();

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };

  }, []);

  // You can return additional data or functions if needed
  return {};
}

export default useHashScroll;
