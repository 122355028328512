import React from "react";
import { HORIZONTAL_VIDEO_PLAYER_URL } from '../config';
import { Aspect } from '@sky-tv-group/components';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { VideoTrigger } from './';

interface FullWidthVideoProps {
    data: object | any;
}

const baseURL = HORIZONTAL_VIDEO_PLAYER_URL.split('/');
const accountId = baseURL[3];


const FullWidthVideo: React.FC<FullWidthVideoProps> = ({ data }) => {

    React.useEffect(()=>{
        VideoTrigger(".video-inset", {rootMargin: '-50%'})
      })
      
    return (
        <>
        <div className="mt-[60px] md:mt-0 md:py-13 pl-6 md:pb-8 md:px-11 lg:px-13">
            <div className="md:col-span-6">
                <div className="text-black text-44px leading-none font-skyBlack mb-4">
                    {documentToReactComponents(data.heading)}
                </div>
            </div>
        </div>
        <div className="video-inset md:mb-18 md:px-11 lg:px-13 bg-white">
            {data.brightCoveVideoId ? 
                <Aspect ratio={9 / 16}>
                    <ReactPlayerLoader accountId={accountId} videoId={data.brightCoveVideoId} options={{ autoplay: false, muted: true, loop: true, playsinline: true}} />
                </Aspect>
            : null}
        </div>
        </>
    )
};

export default FullWidthVideo;
