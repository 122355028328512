import React from "react";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import classNames from 'classnames'

interface ColumnsProps {
    data: object | any;
}

const Columns: React.FC<ColumnsProps> = ({ data }) => {

    const colSpan = 10 / data.columns?.length;

    const dynamicClassName = classNames({
        'col-span-12 md:col-span-5 lg:col-span-4': colSpan === 5,
        'col-span-12 md:col-span-10 lg:col-span-9': colSpan === 10,
    });

    return (
        <div className="mx-3 md:px-0 tech-cols grid grid-cols-12 md:mb-28 max-w-6xl lg:mx-auto md:gap-12 lg:gap-28">
            <div className="col-span-12 md:col-span-2 lg:col-span-3 font-skyBlack text-3xl md:text-lg lg:text-2xl mb-8 md:mb-0">
                {data.heading ?
                    <h2>{data.heading}</h2>
                : null }
            </div>
            {data.columns?.map((item: any, index: any)=>{
                return (
                    <div className={dynamicClassName} key={index}>
                        {item.fields.subtitle ?
                            <h3 className="md:relative font-skyBlack text-xl md:text-4 text-blue-pure mt-0 mb-4 md:-mt-10">{item.fields.subtitle}</h3>
                        : null}
                        {item.fields.image?.fields.file.url ?
                            <>
                            <img className="md:max-h-[380px] md:max-w-[620px] mb-6 hidden md:block" src={item.fields.image.fields.file.url} alt={item.fields.image.fields.file.title} />
                            <img className="max-h-[640px] mb-12 md:hidden" src={item.fields.mobilleImage?.fields?.file.url ? item.fields.mobilleImage?.fields?.file.url : item.fields.image.fields.file.url} alt={item.fields.mobileImage?.fields?.file.title} />
                            </>
                        : null}
                        {item.fields.description ? 
                            <div className="pr-12 mb-12 md:mb-0">
                                {documentToReactComponents(item.fields.description)}
                            </div>
                        : null}
                        {item.fields.largeTitle ? 
                            <div className="text-blue-pure font-skyBold text-6xl md:-mt-4 mb-12 md:mb-0">
                                {item.fields.largeTitle}
                            </div>
                        : null}
                    </div>
                )
            })}
        </div>
    )
};

export default Columns;
