import * as React from 'react';
import { ReactComponent as FAQDown } from '../assets/icons/icon_faq_down.svg';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

declare const window: any;

interface FaqListProps {
data: object | any;
}

const FaqList: React.FC<FaqListProps> = ({ data }) => {

const showAnswer = (e: any, text: any) => {
    e.target.parentNode.childNodes[2].classList.toggle('hidden');
    e.target.parentNode.childNodes[1].childNodes[1].childNodes[0].classList.toggle('rotate-180');
    window.dataLayer.push({ event: 'faqExpand', faqText: text });
};

return (
<div className="pt-24 md:pt-16 pb-20 pl-2 max-w-6xl">
    <h3 className="text-midnight font-skyBlack text-3xl text-skyPurple ml-2 lg:ml-48 md:text-2xl mb-5 mr-5 leading-7 md:tracking-tighter lg:tracking-normal">
        {data.heading}
    </h3>
    {data.faqsList.map((item: any, index: number) => {
        return (
            <div className="ml-2 lg:ml-48 relative border-b-2 border-gray-dark mr-8 xl:-mr-14" key={index}>
                <div
                className="h-16 top-0 w-full left-0 absolute z-20 cursor-pointer block"
                onClick={e => {
                    showAnswer(e, item.fields.question);
                }}>
                </div>
                <div className="grid grid-cols-12 pb-6 pt-6">
                <div className="col-span-11 ">
                    <p className="text-midnight text-2xl md:text-xl font-skyBold">
                    {item.fields.question}
                    </p>
                </div>
                <div className="col-span-1 justify-self-end relative mt-2">
                    <FAQDown />
                </div>
                </div>
                <div className="answer hidden mb-[20px]">
                    {documentToReactComponents(item.fields.answer)}
                </div>
            </div>
            );
        
        return null; 
        })}
    </div>
    );  
};

export default FaqList;
